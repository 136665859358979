import {
  ContentfulContentType,
  ContentSection,
} from "@tiicker/util/lib/contentful/types";
import { Media } from "components/Media/media";
import NotificationsCallout from "components/Notifications/NotificationsCallout";
import Sidebar from "components/Search/Sidebar/Sidebar";
import React, { useState } from "react";
import { useContentfulEntries } from "source/contentful/provider";
import CondensedNavigation from "../CondensedNavigation/CondensedNavigation";
import FullNavigation from "../FullNavigation/FullNavigation";
import styles from "./NavigationBar.module.scss";

type Props = {};

export type LinkItem = {
  text: string;
  href: string;
};

const NavigationBar = (props: Props) => {
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [notificationActive, setNotificationActive] = useState<boolean>(false);
  const [notificationDotVisible, setNotificationDotVisible] =
    React.useState(false);

  const globalSearchDetails = useContentfulEntries<ContentSection>(
    ContentfulContentType.PageContentSection,
    {
      query: {
        content_type: ContentfulContentType.PageContentSection,
        "fields.title": "Global-search",
      },
    }
  );

  const search =
    globalSearchDetails.state === "DONE" &&
    globalSearchDetails.result.items.length > 0
      ? globalSearchDetails.result.items[0]
      : undefined;

  const navLinks: LinkItem[] = [
    { text: "Perks", href: "/perks" },
    { text: "Brands", href: "/brands" },
    { text: "How it Works", href: "/how-it-works" },
    { text: "Insights", href: "/insights" },
    { text: "News", href: "/news" },
    { text: "FAQs", href: "/faq" },
    { text: "Partner with Us", href: "/partner" },
  ];

  return (
    <div className={styles.NavigationBar} id="navbar">
      <div className="container">
        <Media greaterThanOrEqual="lg">
          <FullNavigation
            navLinks={navLinks}
            searchActive={searchActive}
            setSearchActive={setSearchActive}
            notificationActive={notificationActive}
            setNotificationActive={setNotificationActive}
          />
        </Media>
        <Media lessThan="lg">
          <CondensedNavigation
            navLinks={navLinks}
            setSearchActive={setSearchActive}
            notificationActive={notificationActive}
            setNotificationActive={setNotificationActive}
            notificationDotVisible={notificationDotVisible}
          />
          <NotificationsCallout
            isOpen={notificationActive}
            setNotificationActive={setNotificationActive}
            setNotificationDotVisible={setNotificationDotVisible}
            isMobile={true}
          />
        </Media>
      </div>
      {search && (
        <Sidebar
          isOpen={searchActive}
          defaultValues={search}
          includes={
            globalSearchDetails.state === "DONE"
              ? globalSearchDetails.result.includes
              : undefined
          }
          setSearchActive={setSearchActive}
        />
      )}
    </div>
  );
};

export default NavigationBar;
