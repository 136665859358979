import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { LinkItem } from "../NavigationBar/NavigationBar";
import styles from "./CondensedNavigation.module.scss";
import Link from "next/link";
import Button from "components/core/Button/Button";
import { useAuth, useFetchUser, useQueryBundle } from "source/hooks";
import CondensedNavHeader from "../CondensedNavHeader/CondensedNavHeader";
import Container from "components/core/Layout/Container/Container";
import { useUser } from "components/UserContext/UserContext";
import { GetReferralsAssociatedWithUser } from "$gql/queries/general/GetReferralsAssociatedWithUser.gen";
import ReferralStatus from "@tiicker/util/lib/referralStatus";
import { useFlags } from "source/hooks/useFlags";

type Props = {
  navLinks: LinkItem[];
  setSearchActive: (visible: boolean) => void;
  notificationActive: boolean;
  setNotificationActive: (visible: boolean) => void;
  notificationDotVisible: boolean;
};

function CondensedNavigation(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  const userBundle = useFetchUser();
  const userContext = useUser();
  const associatedReferralsQuery = useQueryBundle(
    GetReferralsAssociatedWithUser,
    { notifyOnNetworkStatusChange: true, fetchPolicy: "network-only" }
  );
  const [hasPendingReward, setHasPendingReward] = useState<boolean>(false);
  const flags = useFlags();
  const showReferAFriend =
    flags.referafriend186015210 &&
    (userContext.state.logins.length ||
      (userContext.state.logins.length === 0 && hasPendingReward));

  useEffect(() => {
    if (
      associatedReferralsQuery.state === "DONE" &&
      associatedReferralsQuery.data.getReferralsAssociatedWithUser.length
    ) {
      const pendingRewards =
        associatedReferralsQuery.data.getReferralsAssociatedWithUser.find(
          (referral) => referral.status === ReferralStatus.PENDING
        );

      setHasPendingReward(!!pendingRewards);
    }
  }, [associatedReferralsQuery]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflowY = isOpen ? "hidden" : "scroll";
      body.style.position = isOpen ? "fixed" : "static";
    }
  }, [isOpen]);

  return (
    <div
      className={classNames({ [styles.CondensedNavHeader__navIsOpen]: isOpen })}
    >
      <CondensedNavHeader
        isOpen={false}
        onSearchClick={() => props.setSearchActive(true)}
        onHamburgerClick={() => setIsOpen(true)}
        notificationActive={props.notificationActive}
        setNotificationActive={props.setNotificationActive}
        notificationDotVisible={props.notificationDotVisible}
      />
      <div
        className={classNames(styles.mobileMenu, {
          [styles.mobileMenu_Open]: isOpen,
        })}
      >
        <div className={styles.menuNavWrapper}>
          <Container>
            <CondensedNavHeader
              isOpen={true}
              onSearchClick={() => props.setSearchActive(true)}
              onClose={() => setIsOpen(false)}
              notificationActive={props.notificationActive}
              setNotificationActive={props.setNotificationActive}
              notificationDotVisible={props.notificationDotVisible}
              inverted
            />
          </Container>
        </div>
        <div className={styles.linkList}>
          {userBundle.user && (
            <>
              <Link href="/dashboard">
                <a className={styles.navLink}>
                  <span>Dashboard</span>
                </a>
              </Link>
              <Link href="/my-account">
                <a className={styles.navLink}>
                  <span>My Account</span>
                </a>
              </Link>
              {showReferAFriend && (
                <Link href="/refer-a-friend">
                  <a className={styles.navLink}>
                    <span>Refer Friends</span>
                  </a>
                </Link>
              )}
            </>
          )}

          {props.navLinks.map((link) => (
            <Link
              key={`condensed-nav-${link.href}-${link.text}`}
              href={link.href}
            >
              <a className={styles.navLink}>
                <span>{link.text}</span>
              </a>
            </Link>
          ))}
          {!userBundle.user ? (
            <a onClick={() => auth.login()} className={styles.navLink}>
              <span>Sign In</span>
            </a>
          ) : (
            <>
              <a onClick={() => auth.logout()} className={styles.navLink}>
                <span>Sign Out</span>
              </a>
            </>
          )}
        </div>
        <div className={styles.lowerMenuWrapper}>
          {!userBundle.user ? (
            <div className="container">
              <Button
                onClick={() => auth.smsSignUp()}
                className={styles.joinNow}
              >
                Join Now
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default CondensedNavigation;
