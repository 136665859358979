import classNames from "classnames";
import Button from "components/core/Button/Button";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { LinkItem } from "../NavigationBar/NavigationBar";
import styles from "./FullNavigation.module.scss";
import SearchIcon from "public/icons/magnifying-glass.svg";
import BellIcon from "public/icons/bellicon.svg";
import { useAuth, useFetchUser, useQueryBundle } from "source/hooks";
import MiniMenu from "components/core/MiniMenu/MiniMenu";
import SmallDownArrow from "public/icons/small-down-arrow.svg";
import NotificationsCallout from "components/Notifications/NotificationsCallout";
import { useUser } from "components/UserContext/UserContext";
import { GetReferralsAssociatedWithUser } from "$gql/queries/general/GetReferralsAssociatedWithUser.gen";
import ReferralStatus from "@tiicker/util/lib/referralStatus";
import { useFlags } from "source/hooks/useFlags";

type Props = {
  navLinks: LinkItem[];
  searchActive: boolean;
  setSearchActive: (visible: boolean) => void;
  notificationActive: boolean;
  setNotificationActive: (visible: boolean) => void;
};

const FullNavigation = (props: Props) => {
  const userBundle = useFetchUser();
  const userContext = useUser();
  const auth = useAuth();
  const [notificationDotVisible, setNotificationDotVisible] =
    React.useState(false);
  const associatedReferralsQuery = useQueryBundle(
    GetReferralsAssociatedWithUser,
    { notifyOnNetworkStatusChange: true, fetchPolicy: "network-only" }
  );
  const [hasPendingReward, setHasPendingReward] = useState<boolean>(false);

  useEffect(() => {
    if (
      associatedReferralsQuery.state === "DONE" &&
      associatedReferralsQuery.data.getReferralsAssociatedWithUser.length
    ) {
      const pendingRewards =
        associatedReferralsQuery.data.getReferralsAssociatedWithUser.find(
          (referral) => referral.status === ReferralStatus.PENDING
        );

      setHasPendingReward(!!pendingRewards);
    }
  }, [associatedReferralsQuery]);

  const flags = useFlags();

  let { siteNotifications184605453, referafriend186015210 } = flags;

  const showReferAFriend =
    referafriend186015210 &&
    (userContext.state.logins.length ||
      (userContext.state.logins.length === 0 && hasPendingReward));

  return (
    <div className={styles.NavColumnContainer}>
      <div className={styles.NavColumnContainer__linkWrapper}>
        <Link href={userBundle.user ? "/dashboard" : "/"}>
          <a className={styles.NavColumnContainer__logoLink}>
            <img
              className={styles.NavColumnContainer__logo}
              src="/images/tiicker-logo.svg"
              alt="TiiCKER Logo"
            />
          </a>
        </Link>
        {props.navLinks.map((link) => (
          <Link
            key={`full-nav-link-${link.href}-${link.text}`}
            href={link.href}
          >
            <a
              className={styles.NavColumnContainer__link}
              data-cy={`NavLink-${link.href.split("/")[1]}`}
            >
              <span>{link.text}</span>
            </a>
          </Link>
        ))}
      </div>
      <div className={styles.NavColumnContainer__searchNavWrapper}>
        <SearchIcon
          className={styles.NavColumnContainer__searchIcon}
          onClick={() => props.setSearchActive(true)}
          data-cy="NavSearch"
        />
        <div className={styles.NavColumnContainer__wrapper}>
          {userBundle.user && siteNotifications184605453 && (
            <div
              className={styles.NavColumnContainer__bellIconNotificationAlert}
            >
              <BellIcon
                id="bellIcon"
                className={styles.NavColumnContainer__bellIcon}
                onClick={() => {
                  props.setNotificationActive(!props.notificationActive);
                }}
                data-cy="NotificationBell"
              />
              <div
                className={classNames(
                  notificationDotVisible
                    ? styles.NavColumnContainer__notificationAlert
                    : styles.hidden
                )}
                id="notification-alert"
              />
            </div>
          )}
          <NotificationsCallout
            isOpen={props.notificationActive}
            setNotificationActive={props.setNotificationActive}
            setNotificationDotVisible={setNotificationDotVisible}
          />
        </div>
        {userBundle.loading && <div>Loading</div>}

        {!userBundle.loading && (
          <div className={styles.NavColumnContainer__accountDetailWrapper}>
            {userBundle.user ? (
              <MiniMenu
                activeClass={styles.NavColumnContainer__dropdownText_Active}
                manualOffset={{ y: 15 }}
                position={{ x: "right" }}
                options={[
                  ...(userBundle.admin || userBundle.sales
                    ? [
                        {
                          text: "Admin Console",
                          href: "/admin",
                        },
                      ]
                    : []),
                  ...[
                    {
                      text: "My Dashboard",
                      href: "/dashboard",
                    },
                    {
                      text: "My Account",
                      href: "/my-account",
                    },
                    {
                      text: "Past Claimed Perks",
                      href: "/my-account/past-claimed-perks",
                    },
                    ...(showReferAFriend
                      ? [
                          {
                            text: "Refer Friends",
                            href: "/refer-a-friend",
                          },
                        ]
                      : []),
                    {
                      text: "Log Out",
                      onClick: () => auth.logout(),
                    },
                  ],
                ]}
              >
                <span className={styles.NavColumnContainer__dropdownText}>
                  {userBundle.user.firstName} {userBundle.user.lastName}
                  <SmallDownArrow
                    className={styles.NavColumnContainer__dropdownArrow}
                  />
                </span>
              </MiniMenu>
            ) : (
              <>
                <a
                  className={classNames(
                    styles.NavColumnContainer__link,
                    styles.NavColumnContainer__signInLink
                  )}
                >
                  <span onClick={() => auth.login()}>Sign In</span>
                </a>
                <Button
                  size="small"
                  onClick={() => auth.smsSignUp()}
                  className={styles.NavColumnContainer__signInButton}
                >
                  Join Now
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FullNavigation;
