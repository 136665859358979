import styles from "./NotificationsCallout.module.scss";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useFetchUser, useMutationBundle, useQueryBundle } from "source/hooks";
import { GetActiveGlobalNotifications } from "$gql/queries/admin/GetActiveGlobalNotifications.gen";
import NotificationSection from "./NotificationSection";
import { GetGlobalNotificationAcknowledgementsForUser } from "$gql/queries/admin/GetGlobalNotificationAcknowledgementsForUser.gen";
import { AcknowledgeGlobalNotification } from "$gql/mutations/general/AcknowledgeGlobalNotification.gen";
import { AcknowledgeUserAlert } from "$gql/mutations/general/AcknowledgeUserAlert.gen";
import { GetAlertsForUser } from "$gql/queries/general/GetAlertsForUser.gen";
import { faX } from "@fortawesome/sharp-light-svg-icons";
import * as TimeDateIso from "@tiicker/util/lib/date-iso/time-date-iso";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  isOpen?: boolean;
  isMobile?: boolean;
  setNotificationActive: (visible: boolean) => void;
  setNotificationDotVisible: (visible: boolean) => void;
};

interface Notification {
  id: number;
  title: string;
  description: string;
  link: string;
  iconType: string;
  createdOn: TimeDateIso.Type;
  acknowledged: boolean;
  notificationType: string;
}

const NotificationsCallout = (props: Props) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const globalNotificationsQuery = useQueryBundle(GetActiveGlobalNotifications);
  const userBundle = useFetchUser();
  const notificationsRef = useRef<HTMLDivElement>(null);
  const [acknowledgeGlobalNotification] = useMutationBundle(
    AcknowledgeGlobalNotification
  );
  const [acknowledgeUserAlert] = useMutationBundle(AcknowledgeUserAlert);
  const globalNotificationAcknowledgementsQuery = useQueryBundle(
    GetGlobalNotificationAcknowledgementsForUser,
    { variables: { userId: userBundle.user?.id }, skip: !userBundle.user?.id }
  );
  const userAlertsQuery = useQueryBundle(GetAlertsForUser, {
    variables: { userId: userBundle.user?.id },
    skip: !userBundle.user?.id,
  });

  const globalNotifications =
    globalNotificationsQuery.state === "DONE"
      ? globalNotificationsQuery.data.getActiveGlobalNotifications
      : [];

  const alerts =
    userAlertsQuery.state === "DONE"
      ? userAlertsQuery.data.getAlertsForUser
      : [];

  const globalNotificationAcknowledgements =
    globalNotificationAcknowledgementsQuery.state === "DONE"
      ? globalNotificationAcknowledgementsQuery.data
          .getGlobalNotificationAcknowledgementsForUser
      : [];

  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const handleClickOutside = function (event) {
    if (
      notificationsRef.current &&
      !notificationsRef.current.contains(event.target) &&
      event.target.id !== "bellIcon"
    ) {
      props.setNotificationActive(false);
    }
  };
  const [notificationAcknowledgements, setNotificationAcknowledgements] =
    useState<{ [id: string]: boolean }>();

  useEffect(() => {
    if (
      userBundle.user?.id &&
      userAlertsQuery.state === "DONE" &&
      globalNotificationsQuery.state === "DONE" &&
      globalNotificationAcknowledgementsQuery.state === "DONE"
    ) {
      const userAlerts = alerts.map((userAlert) => {
        return {
          id: userAlert.id,
          title: userAlert.alert
            ? userAlert.alert.title
            : userAlert.customTitle,
          description: userAlert.alert
            ? userAlert.alert.description
            : userAlert.customDescription,
          link: userAlert.alert ? userAlert.alert.link : userAlert.customLink,
          iconType: userAlert.alert ? userAlert.alert.alertType : "syncFailure",
          createdOn: userAlert.createdOn,
          acknowledged: userAlert.acknowledgedAt !== null,
          notificationType: "userAlert",
        };
      }) as Notification[];

      const siteWideNotifications = globalNotifications.map((notification) => {
        return {
          id: notification.id,
          title: notification.title,
          description: notification.description,
          link: notification.link,
          iconType: notification.iconType,
          createdOn: notification.createdOn,
          acknowledged: !!globalNotificationAcknowledgements.find(
            (acknowledgement) =>
              acknowledgement.globalNotificationId === notification.id
          ),
          notificationType: "globalNotification",
        };
      }) as Notification[];

      setNotifications([...siteWideNotifications, ...userAlerts]);
    }
  }, [
    userBundle.user?.id,
    alerts,
    globalNotifications,
    globalNotificationAcknowledgementsQuery.state,
  ]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [notificationsRef]);

  useEffect(() => {
    const unread = notifications
      .map((notification) => (notification.acknowledged ? 0 : 1))
      .reduce((partialSum, b) => partialSum + b, 0);

    setNotificationAcknowledgements(
      notifications.reduce((acc, notification) => {
        acc[notification.id] = notification.acknowledged;
        return acc;
      }, {})
    );

    setUnreadNotificationCount(unread);

    if (unread > 0) {
      props.setNotificationDotVisible(true);
    } else {
      props.setNotificationDotVisible(false);
    }
  }, [notifications]);

  const markAllAsRead = () => {
    notifications.forEach((notification, index) => {
      if (
        userBundle.user &&
        notification.notificationType === "globalNotification"
      ) {
        acknowledgeGlobalNotification({
          variables: {
            userId: userBundle.user.id,
            globalNotificationId: notification.id,
            isRead: true,
          },
        });
      }

      if (userBundle.user && notification.notificationType === "userAlert") {
        acknowledgeUserAlert({
          variables: {
            id: notification.id,
          },
        });
      }

      var element = document.getElementById(
        notification.notificationType + "_" + notification.id.toString()
      );

      if (element) {
        element.className = "";
      }

      setUnreadNotificationCount(0);
      props.setNotificationDotVisible(false);

      setNotificationAcknowledgements(
        notifications.reduce((acc, notification) => {
          acc[notification.id] = true;
          return acc;
        }, {})
      );
    });
  };

  const closeNotificationsDialog = () => {
    props.setNotificationActive(false);
  };

  useEffect(() => {
    const element = document.getElementsByTagName("body")[0];
    if (props.isOpen && props.isMobile) {
      element.style.overflow = "hidden";
    } else {
      element.style.overflow = "auto";
    }
  }, [props.isOpen]);

  return (
    <div className={styles.NotificationCallout}>
      {userBundle.user?.id ? (
        <div
          ref={notificationsRef}
          className={classNames(props.isOpen ? undefined : styles.hidden)}
        >
          <div
            className={classNames(
              props.isMobile
                ? styles.NotificationCallout__mobile__container
                : styles.NotificationCallout__container
            )}
          >
            {!props.isMobile && (
              <div className={styles.NotificationCallout__topRow}>
                <div className={styles.NotificationCallout__title}>
                  <div className={styles.NotificationCallout__header}>
                    Notifications
                  </div>

                  <div className={styles.NotificationCallout__subHeader}>
                    <span className={styles.NotificationCallout__count}>
                      {unreadNotificationCount}
                    </span>
                  </div>
                </div>
                <div
                  className={styles.NotificationCallout__markAllAsRead}
                  onClick={markAllAsRead}
                >
                  Mark all as read
                </div>
              </div>
            )}

            {props.isMobile && (
              <div className={styles.NotificationCallout__mobile__topRow}>
                <div className={styles.NotificationCallout__mobile__titleRow}>
                  <div className={styles.NotificationCallout__mobile__title}>
                    <div className={styles.NotificationCallout__mobile__header}>
                      Notifications
                    </div>

                    <div
                      className={styles.NotificationCallout__mobile__subHeader}
                    >
                      <span
                        className={styles.NotificationCallout__mobile__count}
                      >
                        {unreadNotificationCount}
                      </span>
                    </div>
                  </div>

                  <div
                    className={styles.NotificationCallout__mobile__closeIcon}
                  >
                    <FontAwesomeIcon
                      icon={faX}
                      onClick={closeNotificationsDialog}
                    />
                  </div>
                </div>
                <div
                  className={styles.NotificationCallout__mobile__markAllAsRead}
                  onClick={markAllAsRead}
                >
                  Mark all as read
                </div>
              </div>
            )}

            {!props.isMobile &&
              notifications.map((notification, index) => (
                <NotificationSection
                  key={index}
                  id={notification.id}
                  title={notification.title}
                  description={notification.description}
                  link={notification.link}
                  icon={notification.iconType}
                  createdOn={notification.createdOn}
                  notificationId={notification.id}
                  notificationType={notification.notificationType}
                  unreadNotificationCount={unreadNotificationCount}
                  setUnreadNotificationCount={setUnreadNotificationCount}
                  setNotificationDotVisible={props.setNotificationDotVisible}
                  notificationAcknowledgements={notificationAcknowledgements}
                  setNotificationAcknowledgements={
                    setNotificationAcknowledgements
                  }
                />
              ))}

            {props.isMobile && (
              <div
                className={styles.NotificationCallout__mobile__calloutContainer}
              >
                {notifications.map((notification, index) => (
                  <NotificationSection
                    key={index}
                    id={notification.id}
                    title={notification.title}
                    description={notification.description}
                    link={notification.link}
                    icon={notification.iconType}
                    createdOn={notification.createdOn}
                    notificationId={notification.id}
                    notificationType={notification.notificationType}
                    unreadNotificationCount={unreadNotificationCount}
                    setUnreadNotificationCount={setUnreadNotificationCount}
                    setNotificationDotVisible={props.setNotificationDotVisible}
                    notificationAcknowledgements={notificationAcknowledgements}
                    setNotificationAcknowledgements={
                      setNotificationAcknowledgements
                    }
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default NotificationsCallout;
